@use "sass:math";

// -----------------------------
// CB Variables
// -----------------------------

// ------------------------------------ NAMESPACE

$apricotNamespace: "cb-apricot";
// $apricotNamespace: null;

// ------------------------------------ PATHS

$fontPath: "../../fonts/";

// ------------------------------------ ICONS, Font File

$iconsFile: "cb-icons-4_5_22";

// ------------------------------------ WHITE
$white: #ffffff;

// ------------------------------------ BLACK

$black1: #1e1e1e;
$black2: #151515;
$black: #000;

// ------------------------------------ BLUE

$blue1: #006298;
$blue2: #0077c8; //Link Color , blue accent1
$blue3: #009cde;
$blue4: #71c5e8;

$outlineColor: #7ab6fd;

$blue5: #324dc7; //New brand Color
$blue5-shade1: #28369a;
$blue5-shade2: #1b2264;
$blue5Tint1: #e6edf8; //90
$blue5Tint2: #f5f7fc; //95
// $blue6: #e5f5fc; //selected table cell

// ------------------------------------ PURPLE

$purple1: #702f8a;
$purple2: #a05eb5;

$purple3: #85144b;

// ------------------------------------ GREEN

$green1: #3a913f;
$green2: #6cc24a;
$green3: #1d7846; // Toast

// ------------------------------------ GRAY

// corporate
$gray1: #505050;
$gray2: #888888;
$gray3: #b2b2b2;
$gray4: #d9d9d9;
$gray5: #f0f0f0; //digital

// ------------------------------------ YELLOW

$yellow1: #fedb00;
$yellow2: #fdf4ba; //highlight table cell

$yellow3: #e5c500; //Active button
// ------------------------------------ ORANGE

$orange1: #e57200;

// ------------------------------------ RED

// Old red
// $red1: #c13145; //Text, Alerts
$red1: #ab2334; // Toast

// ------------------------------------ Success
$success: $green3;
$successShade1: #155934;
$successShade2: #0d3921;
$successTint1: #c9dfd3;
$successTint2: #f4f8f6;

// ------------------------------------ Warning
$warning: $red1;
$warningShade1: #7f1a26;
$warningShade2: #521019;
$warningTint1: #eacace;
$warningTint2: #fbf4f5;

// ------------------------------------ Lawrence
$lawrence: #29a285;
$lawrenceShade1: #01614a;
$lawrenceShade2: #0e382e;
$lawrenceTint1: #c5f9e0;
$lawrenceTint2: #e4fcf6;

// ------------------------------------ Mariner
$mariner: #33c8be;
$marinerShade1: #159c93;
$marinerShade2: #024e48;
$marinerTint1: #d0f6f4;
$marinerTint2: #e1fffd;

// ------------------------------------ Cerulean
$cerulean: #33adc8;
$ceruleanShade1: #157c9c;
$ceruleanShade2: #05546d;
$ceruleanTint1: #baeefe;
$ceruleanTint2: #e0f9ff;

// ------------------------------------ Chartreuse
$chartreuse: #bfff00;
$chartreuseShade1: #4f7720;
$chartreuseShade2: #3e5c1b;
$chartreuseTint1: #dffda8;
$chartreuseTint2: #f9ffe0;

// ------------------------------------ Sunshine
$sunshine: #fbae25;
$sunshineShade1: #ad5606;
$sunshineShade2: #682c00;
$sunshineTint1: #ffe7c4;
$sunshineTint2: #fff6e9;

// ------------------------------------ Coral
$coral: #f46643;
$coralShade1: #e03116;
$coralShade2: #991400;
$coralTint1: #ffc2a7;
$coralTint2: #fedecb;

// ------------------------------------ Mulberry
$mulberry: #d237a0;
$mulberryShade1: #9a1481;
$mulberryShade2: #52004a;
$mulberryTint1: #f6dbee;
$mulberryTint2: #fdebf7;

// ------------------------------------ Parakeet
$parakeet: #32c861;
$parakeetShade1: #0e9e3b;
$parakeetShade2: #00621f;
$parakeetTint1: #a7fcc1;
$parakeetTint2: #d4ffe2;

// ------------------------------------ PROGRAM

$k-12: $blue3;
$k-12-accent1: $blue4;
$k-12-accent2: $blue2;

$higher-education: $purple1;
$higher-education-accent1: $purple2;

$green1: $green1;
$green2: $green2;

// ------------------------------------ COLOR MAP

$colorTypes: () !default;
$colorTypes: (
  "bg": background-color,
  "color": color,
  "border": border-color,
);

$colorThemes: () !default;
$colorThemes: map-merge(
  (
    white: (
      name: white,
      code: $white,
      color: 1,
      bg: 1,
      border: 1,
      type: base,
    ),
    black1: (
      name: black-corporate,
      code: $black1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    black2: (
      name: black2-corporate,
      code: $black2,
      color: 0,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    blue1: (
      name: blue-corporate,
      code: $blue1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    blue2: (
      name: accent1-blue-corporate,
      code: $blue2,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    blue3: (
      name: accent2-blue-corporate,
      code: $blue3,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    blue4: (
      name: k-12-accent,
      code: $blue4,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    blue5: (
      name: blue6-corporate,
      code: $blue5,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray1: (
      name: gray1-corporate,
      code: $gray1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray2: (
      name: gray2-corporate,
      code: $gray2,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray3: (
      name: gray3-corporate,
      code: $gray3,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray4: (
      name: gray4-corporate,
      code: $gray4,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    gray5: (
      name: digital-gray,
      code: $gray5,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    yellow1: (
      name: yellow-corporate,
      code: $yellow1,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    orange1: (
      name: orange-corporate,
      code: $orange1,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    red1: (
      name: red1,
      code: $red1,
      color: 1,
      bg: 1,
      border: 1,
      type: base,
    ),
    purple1: (
      name: higher-education,
      code: $purple1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    purple2: (
      name: higher-education-accent,
      code: $purple2,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    green1: (
      name: access,
      code: $green1,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
    green2: (
      name: access-accent,
      code: $green2,
      color: 1,
      bg: 1,
      border: 0,
      type: corporate,
    ),
    green3: (
      name: green3,
      code: $green3,
      color: 1,
      bg: 1,
      border: 1,
      type: corporate,
    ),
  ),
  $colorThemes
);

// ------------------------------------ ADDITIONAL
$additionalThemes: () !default;
$additionalThemes: map-merge(
  (
    lawrence: (
      name: lawrence,
      bg: $lawrence,
      shade-1: $lawrenceShade1,
      shade-2: $lawrenceShade2,
      tint-1: $lawrenceTint1,
      tint-2: $lawrenceTint2,
    ),
    mariner: (
      name: mariner,
      bg: $mariner,
      shade-1: $marinerShade1,
      shade-2: $marinerShade2,
      tint-1: $marinerTint1,
      tint-2: $marinerTint2,
    ),
    cerulean: (
      name: cerulean,
      bg: $cerulean,
      shade-1: $ceruleanShade1,
      shade-2: $ceruleanShade2,
      tint-1: $ceruleanTint1,
      tint-2: $ceruleanTint2,
    ),
    chartreuse: (
      name: chartreuse,
      bg: $chartreuse,
      shade-1: $chartreuseShade1,
      shade-2: $chartreuseShade2,
      tint-1: $chartreuseTint1,
      tint-2: $chartreuseTint2,
    ),
    sunshine: (
      name: sunshine,
      bg: $sunshine,
      shade-1: $sunshineShade1,
      shade-2: $sunshineShade2,
      tint-1: $sunshineTint1,
      tint-2: $sunshineTint2,
    ),
    coral: (
      name: coral,
      bg: $coral,
      shade-1: $coralShade1,
      shade-2: $coralShade2,
      tint-1: $coralTint1,
      tint-2: $coralTint2,
    ),
    mulberry: (
      name: mulberry,
      bg: $mulberry,
      shade-1: $mulberryShade1,
      shade-2: $mulberryShade2,
      tint-1: $mulberryTint1,
      tint-2: $mulberryTint2,
    ),
    parakeet: (
      name: parakeet,
      bg: $parakeet,
      shade-1: $parakeetShade1,
      shade-2: $parakeetShade2,
      tint-1: $parakeetTint1,
      tint-2: $parakeetTint2,
    ),
  ),
  $additionalThemes
);

// ------------------------------------ PALETTES , TINT and SHADES

$paletteColors: (
  blue2: $blue2,
  blue5: $blue5,
  purple1: $purple1,
  green1: $green1,
);

$tintColors: (
  blue2: $blue2,
  purple1: $purple1,
  green1: $green1,
);

// RGBA(code, 0.8)
// https://tdekoning.github.io/rgba-converter/
// https://www.rapidtables.com/convert/color/hex-to-rgb.html

$paletteSVGColors: (
  blue2: #1a84cd,
  blue5: #465fcc,
  purple1: #7e4495,
  green1: #4e9c52,
);

// ------------------------------------ SPACER

$spacer-v: 24;
$spacer-h: 24;

// ------------------------------------ BORDER

$border-color: $gray4;
$border-width: 1px;

// ------------------------------------ BREAKPOINTS

// 7 breakpoints
$grid-breakpoints: (
  xs: 1px,
  sm: 768px,
  md: 1024px,
  lg: 1248px,
  xl: 1344px,
  2xl: 1440px,
  3xl: 1728px,
) !default;

$container-max-widths: (
  xs: 0,
  sm: 720px,
  md: 976px,
  lg: 1200px,
  xl: 1296px,
  2xl: 1392px,
  3xl: 1680px,
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;
$enable-grid-classes: true !default;
$body-bg: $white;

$gutterSize: (24, 32, 48);

// ------------------------------------ PRINT

$grid-breakpoints-print: (
  xs: 1px,
  sm: 1px,
  md: 1px,
) !default;

// Printing
$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, "sm") !default;

// ------------------------------------ WIDTH
$width-height-opt: (
  100: 100%,
  50: 50%,
  auto: auto,
) !default;

// ------------------------------------ WIDTH VIEWPORT
$width-vw-opt: (
  100: 100vw,
  50: 50vw,
) !default;

// ------------------------------------ HEIGHT VIEWPORT
$height-vh-opt: (
  100: 100vh,
  50: 50vh,
) !default;
// ------------------------------------ TEXT

$font-color-base: $black1;

$font-size-base: 16px;
$line-height-base: 1.5em;
$font-family-base: Roboto, sans-serif;
$font-weight-base: 400;
$line-height-base: 1.5 !default;

$font-size-small: 0.875rem; //14
$line-height-small: 1.7142857142857142em; //24

$font-size-xsmall: 0.75rem; //12
$line-height-xsmall: 2em; //24

$font-size-xlarge: 6rem;
$line-height-xlarge: 1em;

$font-weight-bold: 700;
$font-weight-black: 900;

$font-family-roboto: "Roboto", sans-serif;

$font-family-h: $font-family-roboto;

// XS (mobile first) values
$heading: (
  h1: (
    size: 2rem,
    //32
    line: 1.25em //40,,,,,,
  ),
  h2: (
    size: 1.75rem,
    //28
    line: 1.1428571428571428em //32,,,,,,
  ),
  h3: (
    size: 1.3125rem,
    //21
    line: 1.1428571428571428em //24,,,,,,
  ),
  h4: (
    size: 1.1875rem,
    //19
    line: 1.263157894736842em //24,,,,,,
  ),
  h5: (
    size: 1rem,
    //16
    line: 1.5em //24,,,,,,
  ),
  h6: (
    size: 0.875rem,
    //14
    line: 1.1428571428571428em //16,,,,,,
  ),
);

$body: (
  1: (
    size: 1rem,
    line: 1.5em,
    weight: null,
  ),
  2: (
    size: 0.875rem,
    line: 1.7142857142857142em,
    weight: null,
  ),
  3: (
    size: 1.125rem,
    line: 1.3333333333333333em,
    weight: null,
  ),
  4: (
    size: 1.4375rem,
    line: 1.0434782608695652em,
    weight: 300,
  ),
  5: (
    size: 1.4375rem,
    line: 1.0434782608695652em,
    weight: 700,
  ),
  6: (
    size: 1.5rem,
    line: 1.3333333333333333em,
    weight: 700,
  ),
);

$font-size-sm: $font-size-base * 0.875 !default;
$zindex-tooltip: 1070 !default;

$border-radius: 8px;
$border-radius-input: $border-radius;

$textAlign: (left, right, center);

$cursor: (default, none, help, auto, pointer, progress, wait, not-allowed);

$fontWeight: (
  thin: 100,
  extra-light: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  black: 900,
);

// ------------------------------------ LINK

$link-color: $blue5;
$link-active-color: $blue5-shade1;
$link-hover-color: $blue5;
$link-visited-color: $purple3;

$link-color-black: $black1;
$link-active-color-black: $gray1;
$link-hover-color-black: $black1;

// ------------------------------------ LIST

$list-offset-left: 24px;

// ------------------------------------ BORDERS
$borderType: (top, right, bottom, left);

$borderStyle: (solid, dashed);

$radiusSize: (8, 16);

$borderWidth: (1, 2, 4, 8, 16, 24);
// ------------------------------------ SPACER, MARGIN, PADDING

$spacersH: (
  4: (
    math.div($spacer-h, 6),
  ),
  8: (
    math.div($spacer-h, 3),
  ),
  16: (
    16,
  ),
  24: $spacer-h,
  32: (
    32,
  ),
  72: (
    $spacer-h * 3,
  ),
);

$spacersV: (
  8: (
    math.div($spacer-v, 3),
  ),
  16: (
    16,
  ),
  24: (
    $spacer-v,
  ),
  32: (
    32,
  ),
  48: (
    $spacer-v * 2,
  ),
  72: (
    $spacer-v * 3,
  ),
);

// MARGIN + PADDING
$mpValue: 4, 8, 16, 24, 32, 48, 72;
$mpList: "top", "bottom", "left", "right";

// ------------------------------------ LOGOS
$svgLogos: "logo-acorn", "logo", "programs-access", "programs-accuplacer", "cb-programs-ap",
  "programs-ap", "programs-clep", "programs-css-profile", "programs-powerfaids", "programs-pre-ap",
  "programs-psat-8-9", "programs-psat-10", "programs-psat-nmsqt", "programs-sat",
  "programs-springboard", "search", "programs-college-planning";

// ------------------------------------ GLYPHS
$logoGlyphs: (
  "logo-acorn": e901,
  "logo": e902,
  "programs-access": e903,
  "programs-accuplacer": e904,
  "cb-programs-ap": e905,
  "programs-ap": e905,
  "programs-clep": e906,
  "programs-css-profile": e907,
  "programs-powerfaids": e908,
  "programs-pre-ap": e909,
  "programs-psat-8-9": e90a,
  "programs-psat-10": e90b,
  "programs-psat-nmsqt": e90c,
  "programs-sat": e90d,
  "programs-springboard": e90e,
  "search": e90f,
  "programs-college-planning": e900,
  "programs-big-future": e911,
  "higher-ed-search": e910,
);

// ------------------------------------ DISPLAYS

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$overflows: visible, hidden, scroll, auto !default;
$visibility: visible, hidden !default;
// ------------------------------------ POSITIONS

$positions: relative, absolute, fixed, static, sticky !default;
$zIndex: 1, 2, 9, 90, 99, 900, 909, 990, 999, 9999 !default;
$positionsOrg: top, right, bottom, left !default;

// ------------------------------------ TEXT FORMAT
$white-space: normal, nowrap, pre, pre-line, pre-wrap, initial, inherit !default;
$overflow-wrap: normal, anywhere, break-word, initial, inherit !default;
// ------------------------------------ BUTTON

$btn-font-family: $font-family-roboto;
$btn-font-color: $blue5;
$btn-font-weight: 700;
$btn-font-size: 0.875rem;
$btn-line-height: 1.7142857142857142em; //24
$btn-line-height-safari: 1.6428571428571428em; //23

$btn-border-width: 1px;
$btn-border-color: $blue5;
$btn-border-radius: 999px;
$btn-border-radius-input: $border-radius;
$btn-padding-v: 12px;
$btn-padding-h: 24px;

$btn-font-family-sm: $font-family-roboto;
$btn-padding-sm-v: 8px;
$btn-padding-sm-h: 24px;
$btn-font-size-sm: $font-size-small; //14px;
$btn-line-height-sm: 1.1428571428571428em; //16px;
$btn-line-height-sm-safari: 1.0714285714285714em; //15px;

$btn-padding-lg-v: 20px;
$btn-padding-lg-h: 48px;
$btn-font-size-lg: 1.1875rem; //19px;
$btn-line-height-lg: 1.263157894736842em; //24px;

$transition-time-s: 0.25s;
$transition-time: 0.3s;
$transition-time-l: 0.35s;

$btn-transition: all $transition-time-s ease-in-out;

$btn-disabled-opacity: 1;

$dotWidth: 6px;
$dotHeight: 6px;
$dotRadius: 50%;
$dotSpacing: 12px;

$dotBgColor: transparent;
$dotColor: $blue5;

$d-max: 50px;
$d-min: 12px;

$btn-blue-action: rgba(50, 77, 199, 0.1);
$btn-blue-action-focus: rgba(50, 77, 199, 0.4);

// ------------------------------------ FORMS

$gray-light-border-color: #e5e2e0;
$cb-disabled-font-color: #989795;

//Forms
$input-bk: $gray5;

$input-focus: $blue5;

$input-border: $gray4;
$input-border-hover: $gray4;

$input-border-focus: $blue5;
$input-hover-shadow: #e7e6e5;
$input-placeholder: #737373;
$input-bg-disabled: #eee;

$input-padding-v: 12px;
$input-padding-h: 16px;

// Form Validation
$success-color: $green3;
$error-color: $red1;
$warning-color: #f90;

$success-glyph: "cb-check";
$error-glyph: "cb-x-mark";
$warning-glyph: "cb-exclamation";

// ------------------------------------ TABLES

$table-border-color: $gray4;
$table-border-width: 1px;
$table-cell-padding: 11px 8px 12px 8px;
$table-cell-padding-light: 11px 8px 11px 8px;
$table-condensed-cell-padding: 3px 4px 4px 4px;

// ------------------------------------ TOOLTIP

$tooltip-font-size: $font-size-sm !default;
$tooltip-min-width: 180px !default;
$tooltip-max-width: 360px !default;
$tooltip-color: $white !default;
$tooltip-bg: $gray1 !default;
$tooltip-border-radius: $border-radius;
$tooltip-border-color: $white !default;
$tooltip-opacity: 0.9 !default;
$tooltip-spacer: 12px !default;

// ------------------------------------ MODALS

// Modal
$zindex-modal-background: 1040;
$zindex-modal-background-high: 1075; //higher than tooltip
$modal-backdrop-bg: rgba(30, 30, 30, 0.9); //$black3;

$modal-bg: $white;
$modal-opacity: 0.9;
$modal-border-radius: $border-radius;
$modal-content-border-color: rgba(0, 0, 0, 0.1);
// Modal content border color **for IE8**
$modal-content-fallback-border-color: $gray3;
$modal-spacing: 24px;

// ------------------------------------ typography
$columnCount: (2, 3, 4);

// ------------------------------------ TOAST
// Transitions
$toast-transition-ease: cubic-bezier(0.22, 0.61, 0.35, 1) !default;
$toast-transition-duration: 0.8s !default;

$zindex-toast: 1080;
// For one line toast
$toast-height: 48px;
// Space between two toasts
$toast-space: 16px;
$blur-rate: 10px !default;

// toast transition properties
$enter-translate: (
  transform: translateY(-$toast-height) translateY(0),
  opacity: 0 1,
  filter: blur(0) blur($blur-rate),
);

$enter-translate-sibling: (
  transform: translateY(-$toast-height) translateY(0),
);

$leave-translate: (
  opacity: 0 1,
  filter: blur($blur-rate) blur(0),
);

// ------------------------------------ DATE PICKER

$day-size: 36px;
$day-size-double: 40px;
$day-of-week-font-size: 14px !default;
$day-of-week-font-weight: 500 !default;
$day-font-size: 14px !default;
$day-font-weight: 500 !default;
$day-disabled-opacity: 0.3;
// increased from .3 to .49 for A11Y
$day-previous-next-opacity: 0.49 !default;

$today-color: $blue5 !default;
$selected-date-color: #fff !default;

$month-padding: 0;

// default: 1rem
$glyphSize: () !default;
$glyphSize: map-merge(
  (
    "xs": 1,
    "sm": 2,
    "md": 3,
    "lg": 4,
    "xl": 5,
  ),
  $glyphSize
);

$glyphs: () !default;
$glyphs: map-merge(
  (
    "online-tool": e936,
    "download": e914,
    "batch-download": e902,
    "menu-local": e925,
    "filter": e91d,
    "sort": e947,
    "sort-desc": e913,
    "sort-asc": e950,
    "up": e950,
    "down": e913,
    "left": e921,
    "right": e940,
    "dbl-left": e910,
    "dbl-right": e911,
    "mail": e928,
    "fax": e91c,
    "home": e91f,
    "location": e926,
    "phone-alt": e938,
    "resources": e93f,
    "cal-full": e907,
    "east": e916,
    "cal-empty": e906,
    "note": e935,
    "lightbulb": e922,
    "question": e93c,
    "megaphone": e92a,
    "plus": e93a,
    "minus": e92c,
    "chat-bubble": e909,
    "facebook": e91b,
    "linkedin": e923,
    "twitter": e94f,
    "youtube": e956,
    "instagram": e920,
    "compose": e90d,
    "upload": e951,
    "list": e924,
    "user": e952,
    "settings": e944,
    "error": e918,
    "book": e905,
    "tag": e94c,
    "check": e90b,
    "menu": e92b,
    "exclamation": e919,
    "draft-in": e915,
    "new-window": e930,
    "no-draft": e931,
    "nav-links": e92d,
    "quote-right": e93e,
    "quote-left": e93d,
    "x-mark": e955,
    "circle": e90c,
    "disc": e912,
    "north-east": e932,
    "creditcard": e90f,
    "educator": e917,
    "series": e943,
    "bell": e904,
    "pending": e937,
    "signed-in": e946,
    "matrix": e929,
    "search": e942,
    "cart": e908,
    "globe": e91e,
    "acorn": e957,
    "batch-upload": e903,
    "needs-score": e92e,
    "test-scored": e94e,
    "save-date": e941,
    "north-west": e933,
    "west": e953,
    "south-west": e94a,
    "south-east": e949,
    "barchart": e901,
    "print": e93b,
    "chat": e90a,
    "window": e954,
    "bag": e900,
    "expand-window": e91a,
    "task-complete": e94d,
    "new-doc": e92f,
    "sound": e948,
    "computer": e90e,
    "share": e945,
    "lock": e927,
    "phone": e939,
    "disc-alt": e958,
    "box-fill": e959,
    "box": e95a,
    "play": e95b,
    "check-alt": e95c,
    "check-circle": e95d,
    "see-on": e95e,
    "see-off": e95f,
    "exclamation-circle": e960,
    "full-screen": e961,
    "exit-full-screen": e962,
    "slideshow-play": e963,
    "slideshow-pause": e964,
    "gallery-forward": e966,
    "gallery-backward": e965,
    "carousel-backward": e967,
    "carousel-forward": e968,
    "carousel-backward-sm": e969,
    "carousel-forward-sm": e96a,
    "refresh": e96b,
    "attachment": e96c,
    "order-desc": e96d,
    "order-asc": e96e,
    "laptop": e96f,
  ),
  $glyphs
);

// default: 1rem
$multiGlyphSize: (
  "sm": 2,
  "lg": 4,
  "xl": 6,
);

$multiGlyph: (
  "accessible": (
    "before": e900,
    "after": e97c,
  ),
  "app": (
    "before": e901,
    "after": e97d,
  ),
  "bag": (
    "before": e902,
    "after": e97e,
  ),
  "barchart": (
    "before": e903,
    "after": e97f,
  ),
  "batch-download": (
    "before": e904,
    "after": e980,
  ),
  "batch-upload": (
    "before": e905,
    "after": e981,
  ),
  "bell": (
    "before": e906,
    "after": e982,
  ),
  "book": (
    "before": e907,
    "after": e983,
  ),
  "bullet-list": (
    "before": e908,
    "after": e984,
  ),
  "bullet-list-doc": (
    "before": e909,
    "after": e985,
  ),
  "calculator": (
    "before": e90a,
    "after": e986,
  ),
  "calendar-empty": (
    "before": e90b,
    "after": e987,
  ),
  "calendar-full": (
    "before": e90c,
    "after": e988,
  ),
  "cart": (
    "before": e90d,
    "after": e989,
  ),
  "chat": (
    "before": e90e,
    "after": e98a,
  ),
  "check": (
    "before": e910,
    "after": e98b,
  ),
  "check-list": (
    "before": e911,
    "after": e98c,
  ),
  "check-list-doc": (
    "before": e912,
    "after": e98d,
  ),
  "college-reputation": (
    "before": e914,
    "after": e98e,
  ),
  "compose": (
    "before": e915,
    "after": e98f,
  ),
  "computer": (
    "before": e916,
    "after": e990,
  ),
  "credit-card": (
    "before": e917,
    "after": e991,
  ),
  "dbl-left": (
    "before": e91a,
    "after": e994,
  ),
  "dbl-right": (
    "before": e91b,
    "after": e995,
  ),
  "dollar": (
    "before": e91d,
    "after": e997,
  ),
  "download": (
    "before": e91f,
    "after": e999,
  ),
  "educator": (
    "before": e920,
    "after": e99a,
  ),
  "error": (
    "before": e922,
    "after": null,
  ),
  "everyday-words": (
    "before": e924,
    "after": e99c,
  ),
  "expand-window": (
    "before": e926,
    "after": e99e,
  ),
  "fax": (
    "before": e929,
    "after": e9a0,
  ),
  "full-length-practice": (
    "before": e92b,
    "after": e9a1,
  ),
  "globe": (
    "before": e967,
    "after": e965,
  ),
  "graduate-pro-school": (
    "before": e92d,
    "after": e9a3,
  ),
  "graduation": (
    "before": e92e,
    "after": e9a4,
  ),
  "home": (
    "before": e92f,
    "after": e9a5,
  ),
  "information": (
    "before": e930,
    "after": e9a6,
  ),
  "instagram": (
    "before": e931,
    "after": null,
  ),
  "facebook": (
    "before": e928,
    "after": null,
  ),
  "youtube": (
    "before": e97b,
    "after": null,
  ),
  "linkedin": (
    "before": e934,
    "after": null,
  ),
  "twitter": (
    "before": e972,
    "after": null,
  ),
  "light-bulb": (
    "before": e933,
    "after": e9a8,
  ),
  "list": (
    "before": e935,
    "after": e9a9,
  ),
  "local-menu": (
    "before": e936,
    "after": e9aa,
  ),
  "location": (
    "before": e937,
    "after": e9ab,
  ),
  "lock": (
    "before": e938,
    "after": e9ac,
  ),
  "lock-open": (
    "before": e939,
    "after": e9ad,
  ),
  "mail": (
    "before": e93a,
    "after": e9ae,
  ),
  "matrix": (
    "before": e93b,
    "after": e9af,
  ),
  "megaphone": (
    "before": e93c,
    "after": e9b0,
  ),
  "menu": (
    "before": e93d,
    "after": e9b1,
  ),
  "minus": (
    "before": e93e,
    "after": e9b2,
  ),
  "nav-links": (
    "before": e93f,
    "after": e9b3,
  ),
  "new-doc": (
    "before": e941,
    "after": e9b5,
  ),
  "new-window": (
    "before": e942,
    "after": e9b6,
  ),
  "note": (
    "before": e947,
    "after": e9b9,
  ),
  "online-tool": (
    "before": e948,
    "after": e9ba,
  ),
  "pending": (
    "before": e949,
    "after": e9bb,
  ),
  "phone": (
    "before": e94a,
    "after": e9bc,
  ),
  "phone-alt": (
    "before": e94b,
    "after": e9bd,
  ),
  "plus": (
    "before": e94c,
    "after": e9be,
  ),
  "practice": (
    "before": e94d,
    "after": e9bf,
  ),
  "practice-test": (
    "before": e94e,
    "after": e9c0,
  ),
  "presentation": (
    "before": e94f,
    "after": e9c1,
  ),
  "print": (
    "before": e950,
    "after": e9c2,
  ),
  "problem-feedback": (
    "before": e951,
    "after": e9c3,
  ),
  "raided-pencil": (
    "before": e955,
    "after": e9c5,
  ),
  "resources": (
    "before": e956,
    "after": e9c6,
  ),
  "rupee": (
    "before": e958,
    "after": e9c8,
  ),
  "save-date": (
    "before": e959,
    "after": e9c9,
  ),
  "science": (
    "before": e95d,
    "after": e9cc,
  ),
  "search": (
    "before": e95e,
    "after": e9cd,
  ),
  "series": (
    "before": e95f,
    "after": e9ce,
  ),
  "settings": (
    "before": e960,
    "after": e9cf,
  ),
  "share": (
    "before": e961,
    "after": e9d0,
  ),
  "share-alt": (
    "before": e962,
    "after": e9d1,
  ),
  "sound": (
    "before": e966,
    "after": e9d4,
  ),
  "special-edition": (
    "before": e969,
    "after": e9d5,
  ),
  "tag": (
    "before": e96b,
    "after": e9d6,
  ),
  "task-complete": (
    "before": e96d,
    "after": e9d8,
  ),
  "teacher-edition": (
    "before": e96e,
    "after": e9d9,
  ),
  "team-leader": (
    "before": e96f,
    "after": e9da,
  ),
  "test-scored": (
    "before": e970,
    "after": e9db,
  ),
  "tuition-financial-aid": (
    "before": e971,
    "after": e9dc,
  ),
  "upload": (
    "before": e974,
    "after": e9de,
  ),
  "user": (
    "before": e975,
    "after": null,
  ),
  "video-lesson": (
    "before": e976,
    "after": e9df,
  ),
  "window": (
    "before": e978,
    "after": null,
  ),
  "work-together": (
    "before": e979,
    "after": e9e0,
  ),
  "quote-left": (
    "before": e913,
    "after": e91c,
  ),
  "quote-right": (
    "before": e91e,
    "after": e919,
  ),
  "add-user": (
    "before": e9e2,
    "after": e9ec,
  ),
  "dollars": (
    "before": e9e3,
    "after": e9ed,
  ),
  "essential-math": (
    "before": e9e4,
    "after": e9ee,
  ),
  "explore": (
    "before": e9e5,
    "after": e9ef,
  ),
  "face-happy": (
    "before": e9e6,
    "after": e9d3,
  ),
  "face-unhappy": (
    "before": e9e7,
    "after": e9d3,
  ),
  "face-unsure": (
    "before": e9e8,
    "after": e9d3,
  ),
  "maths": (
    "before": e9ea,
    "after": e9f3,
  ),
  "school": (
    "before": e9eb,
    "after": e9f4,
  ),
  "exam": (
    "before": e921,
    "after": e944,
  ),
  "khan-academy": (
    "before": e90f,
    "after": null,
  ),
  "send-scores": (
    "before": e925,
    "after": e945,
  ),
  "submit-documents": (
    "before": e92a,
    "after": e946,
  ),
  "submit-portfolio": (
    "before": e932,
    "after": e952,
  ),
  "play": (
    "before": e953,
    "after": null,
  ),
  "signed-in": (
    "before": e957,
    "after": e954,
  ),
  "laptop": (
    "before": e96a,
  ),
  "laptop-check": (
    "before": e96a,
    "after": e968,
  ),
);

// --------- Icons
$iconSize: (12, 14, 16, 24, 32);
$icons: () !default;
$icons: map-merge(
  (
    "accessibility": e900,
    "acorn": e901,
    "apple": e902,
    "award-medal": e903,
    "book-open": e904,
    "book": e905,
    "bullet-list": e906,
    "calculator": e907,
    "calendar-add": e908,
    "calendar-save-the-date": e909,
    "cart": e981,
    "checkbox": e90a,
    "checklist": e90b,
    "clipboard-check": e90c,
    "clipboard": e90d,
    "coin": e90e,
    "document-award": e90f,
    "dollar": e910,
    "download-alt": e911,
    "expand-window": e912,
    "explore": e913,
    "face-bad": e914,
    "face-excellent": e915,
    "face-good": e916,
    "face-neutral": e917,
    "face-very-bad": e918,
    "feedback": e919,
    "financial-aid": e91a,
    "globe": e91b,
    "graduate": e91c,
    "graph": e91d,
    "home": e91e,
    "image": e91f,
    "info-page": e920,
    "info": e9be,
    "khan-academy": e922,
    "laptop-exclamation": e923,
    "lightbulb": e924,
    "list": e925,
    "math-essential": e926,
    "microscope": e927,
    "new-page": e928,
    "new-window": e929,
    "note": e92a,
    "notepad-check": e92b,
    "pages": e92c,
    "paper-airplane": e92d,
    "practice-test": e92e,
    "practice": e92f,
    "quotes": e930,
    "raised-pencil": e931,
    "sat": e98d,
    "school-urban": e932,
    "school": e933,
    "send-scores": e934,
    "share-alt": e935,
    "stopwatch": e936,
    "submit": e937,
    "tablet": e938,
    "task-complete": e939,
    "teacher": e93a,
    "team-leader": e93b,
    "trophy": e93c,
    "twitter": e93d,
    "university": e93e,
    "upload": e93f,
    "cal-empty": e940,
    "cal-full": e941,
    "compose": e942,
    "heart": e943,
    "hyperlink": e944,
    "minus": e945,
    "pending": e946,
    "phone": e947,
    "plus": e948,
    "settings": e949,
    "tool": e94a,
    "user-add": e94b,
    "window": e94c,
    "attachment": e94d,
    "box": e94e,
    "lg-left": e94f,
    "lg-right": e950,
    "check-circle": e9bf,
    "exclamation-circle": e9be,
    "check-fill": e951,
    "exclamation-fill": e952,
    "check": e953,
    "circle": e954,
    "box-fill": e955,
    "compose-cell": e956,
    "compose-pen": e957,
    "computer": e958,
    "disc-alt": e959,
    "disc": e95a,
    "down": e95b,
    "download": e95c,
    "east": e95d,
    "error": e95e,
    "location-tag": e95f,
    "order-asc": e960,
    "pause": e961,
    "pause-circle": e9bd, 
    "exclamation": e962,
    "exit-full-screen": e963,
    "facebook": e964,
    "filter": e965,
    "full-screen": e966,
    "instagram": e968,
    "laptop": e969,
    "left": e96a,
    "linkedin": e96b,
    "location-pin": e96c,
    "order-desc": e96d,
    "location": e96e,
    "lock-open": e96f,
    "lock": e970,
    "mail": e971,
    "megaphone": e972,
    "menu": e973,
    "north-east": e975,
    "north-west": e976,
    "north": e977,
    "chat": e978,
    "bell": e979,
    "copy": e97a,
    "folder": e97b,
    "carousel-backward-sm": e97c,
    "carousel-forward-sm": e97e,
    "carousel-backward": e97f,
    "carousel-forward": e980,
    "gallery-backward": e982,
    "gallery-forward": e983,
    "play": e984,
    "play-circle": e9bc,
    "play-video": e984,
    "print": e986,
    "question": e987,
    "question-fill": e9b8,
    "question-circle": e97d,
    "refresh": e988,
    "right": e989,
    "search": e98a,
    "see-off": e98b,
    "see-on": e98c,
    "share-arrow": e98e,
    "share": e98f,
    "sort-asc": e991,
    "sort-desc": e990,
    "sort": e992,
    "south": e993,
    "tag": e994,
    "up": e995,
    "user-circle": e996,
    "user-remove": e985,
    "user": e997,
    "west": e998,
    "x-mark": e999,
    "youtube": e99a,
    "more": e967,
    "more-alt": e974,
    "vert-grab-handle":e99b,
    "undo":e99c,
    "trash":e99d,
    "text-style-off":e99e,
    "underline":e99f,
    "bold":e9a0,
    "italic":e9a1,
    "table-ico":e9a2,
    "superscript":e9a3,
    "subscript":e9a4,
    "save":e9a5,
    "bookmark":e9a6,
    "left-double":e9a7,
    "right-double":e9a8,
    "stop":e9a9,
    "redo":e9aa,
    "right-align":e9ab,
    "center-align":e9ac,
    "indent":e9ad,
    "left-align":e9ae,
    "pi":e9af,
    "paste":e9b0,
    "omega":e9b1,
    "number-list":e9b2,
    "logout":e9b3,
    "layout-right-pane":e9b4,
    "layout-50-50":e9b5,
    "layout-left-pane":e9b6,
    "archive":e9b7,
    "code":e9b9,
    "cut":e9ba,
    "horz-grab-handle":e9bb
  ),
  $icons
);

// --------- z-index

// dropdownMenu: 899
// Table, sticky Header: 900
// Local nav: 901
// photoGallery: 999
// menu-bar: 1023
// modal: 1040
// tooltip: 1070
// toast: 1080

//----------- flags

$flags: (
  ad,
  ae,
  af,
  ag,
  al,
  am,
  ao,
  ar,
  at,
  au,
  az,
  ba,
  bb,
  bd,
  be,
  bf,
  bg,
  bh,
  bi,
  bj,
  bn,
  bo,
  br,
  bs,
  bt,
  bw,
  by,
  bz,
  ca,
  cd,
  cf,
  cg,
  ch,
  ci,
  cl,
  cm,
  cn,
  co,
  cr,
  cu,
  cv,
  cy,
  cz,
  de,
  dj,
  dk,
  dm,
  do,
  dz,
  ec,
  ee,
  eg,
  er,
  es,
  et,
  fi,
  fj,
  fm,
  fr,
  ga,
  gb,
  gd,
  ge,
  gh,
  gm,
  gn,
  gq,
  gr,
  gt,
  gw,
  gy,
  hn,
  hr,
  ht,
  hu,
  id,
  ie,
  il,
  in,
  iq,
  ir,
  is,
  it,
  jm,
  jo,
  jp,
  ke,
  kg,
  kh,
  ki,
  km,
  kn,
  kp,
  kr,
  kw,
  kz,
  la,
  lb,
  lc,
  li,
  lk,
  lr,
  ls,
  lt,
  lu,
  lv,
  ly,
  ma,
  mc,
  md,
  me,
  mg,
  mh,
  mk,
  ml,
  mm,
  mn,
  mr,
  mt,
  mu,
  mv,
  mw,
  mx,
  my,
  mz,
  na,
  ne,
  ng,
  ni,
  nl,
  no,
  np,
  nr,
  nz,
  om,
  pa,
  pe,
  pg,
  ph,
  pk,
  pl,
  pt,
  pw,
  py,
  qa,
  ro,
  rs,
  ru,
  rw,
  sa,
  sb,
  sc,
  sd,
  se,
  sg,
  si,
  sk,
  sl,
  sm,
  sn,
  so,
  sr,
  ss,
  st,
  sv,
  sy,
  sz,
  td,
  tg,
  th,
  tj,
  tl,
  tm,
  tn,
  to,
  tr,
  tt,
  tv,
  tz,
  ua,
  ug,
  us,
  uy,
  uz,
  va,
  vc,
  ve,
  vn,
  vu,
  ws,
  xk,
  ye,
  za,
  zm,
  zw,
  aw,
  cw,
  vi,
  vg,
  ky,
  mo,
  mm,
  sx,
  ps,
  tc,
  im,
  bm,
  zzz
);
